import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { Container, Box, Button, Typography, TextField, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';
import { hostConfig } from '../../config';

const stripePromise = loadStripe('pk_test_51P74pVIQ06ve5VRvGs2NtWKfCzpLEQGzZXoyTFlz33ZOoMI1PdFMlldv9FeTFBGAcab4vAIHw4l6MECjXnPhea7Y00zYON4Cge');


const StyledCardElement = styled(CardElement)({
    marginTop: '16px',
    padding: '16px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  });
  
  const CustomerForm = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);
    const stripe = useStripe();
    const elements = useElements();
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      if (!stripe || !elements) {
        return;
      }
  
      setLoading(true);
  
      const cardElement = elements.getElement(CardElement);
  
      try {
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            email,
            name,
          },
        });
  
        if (error) {
          console.error(error);
          setLoading(false);
          return;
        }
  
        const { data } = await axios.post(`${hostConfig.API_BASE}/savepaymentmethod`, {
          email,
          name,
          payment_method: paymentMethod.id,
        }, {
          headers: {
            'Authorization': `Bearer YOUR_SECRET_KEY`
          }
        });
  
        console.log('Customer created or updated and payment method saved:', data);
  
      } catch (error) {
        console.error('Error saving customer and payment method:', error);
      } finally {
        setLoading(false);
      }
    };
  
    return (
      <Container maxWidth="sm">
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 4 }}>
          <Typography variant="h5" component="h2" gutterBottom>
            Customer Information
          </Typography>
          <TextField
            label="Email"
            fullWidth
            margin="normal"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Name"
            fullWidth
            margin="normal"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <StyledCardElement />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
            disabled={!stripe || loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Save Payment Method'}
          </Button>
        </Box>
      </Container>
    );
  };
  
  const SaveCCInfo = () => {
    return (
      <Elements stripe={stripePromise}>
        <CustomerForm />
      </Elements>
    );
  };
  
  export default SaveCCInfo;