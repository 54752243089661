

// App.js
import React from 'react';
import axios from 'axios';
import { Container, Box, Button, Typography } from '@mui/material';
import { hostConfig } from '../../../config';

const chargeCustomer = async (customerId, amount) => {
  try {
    const response = await axios.post(`${hostConfig.API_BASE}/customercharge`, {
      customerId,
      amount,
      currency: 'usd',
    });

    if (response.data.success) {
      console.log('Payment successful:', response.data.success);
    }
  } catch (error) {
    console.error('Error charging customer:', error);
  }
};

const PaymentButton = ({ customerId, amount }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      onClick={() => chargeCustomer(customerId, amount)}
    >
      Charge Customer
    </Button>
  );
};

const ChargeButton = () => {
  // Example customer ID and amount (in cents)
  const customerId = 'cus_123456789';
  const amount = 5000; // $50.00

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Unpaid Sessions
        </Typography>
        {/* Example unpaid session */}
        <Box sx={{ mb: 2 }}>
          <Typography variant="body1">Session with Customer X - $50.00</Typography>
          <PaymentButton customerId={customerId} amount={amount} />
        </Box>
      </Box>
    </Container>
  );
};

export default ChargeButton;
