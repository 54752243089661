import React, { useState, useEffect } from 'react';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import axios from 'axios';
import { hostConfig } from '../../config';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Button, Snackbar } from "@mui/material";
import GoogleIcon from '@mui/icons-material/Google';

const GRegistration = ({ preferredTimezone }) => {
    const [user, setUser] = useState(null);
    const [profile, setProfile] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => setUser(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    useEffect(() => {
        if (user) {
            axios
                .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                    headers: {
                        Authorization: `Bearer ${user.access_token}`,
                        Accept: 'application/json'
                    }
                })
                .then((res) => {
                    setProfile(res.data);
                    console.log(res.data, "use effect console log");

                    const patientFirstname = res.data.given_name;
                    const patientLastname = res.data.family_name;
                    const patientEmail = res.data.email;
                    const patientPassword = '';
                    const registration_source = 'Google';

                    axios.post(`${hostConfig.API_BASE}/googlepatientregistration`, {
                        patientFirstname,
                        patientLastname,
                        patientEmail,
                        patientPassword,
                        preferredTimezone,
                        registration_source
                    })
                    .then(response => {
                        console.log('Registration successful', response);
                        setSnackbarOpen(true);
                        setTimeout(() => {
                            navigate("/patientlogin");
                        }, 2000);
                    })
                    .catch(error => {
                        if (error.response) {
                            console.log('Error occurred while registering:', error.response.data);
                        } else if (error.request) {
                            console.log('Error occurred: No response received');
                        } else {
                            console.log('Error occurred:', error.message);
                        }
                    });
                })
                .catch((err) => console.log(err));
        }
    }, [user, preferredTimezone, navigate]);

    const logOut = () => {
        googleLogout();
        setProfile(null);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    return (
        <div>
            {profile ? (
                <Button
                    variant="contained"
                    onClick={logOut}
                    fullWidth
                    sx={{
                        mt: 2,
                        backgroundColor: '#DB4437',
                        '&:hover': {
                            backgroundColor: '#C23321',
                        },
                    }}
                >
                    {t('googleregistration.logout')}
                </Button>
            ) : (
                <Button
                    variant="outlined"
                    startIcon={<GoogleIcon />}
                    onClick={() => login()}
                    fullWidth
                    sx={{
                        color: '#757575',
                        backgroundColor: '#ffffff',
                        borderColor: '#dadce0',
                        textTransform: 'none',
                        fontFamily: 'Roboto, sans-serif',
                        fontWeight: 500,
                        fontSize: '14px',
                        height: '40px',
                        '&:hover': {
                            backgroundColor: '#f1f3f4',
                            borderColor: '#dadce0',
                        },
                    }}
                >
                    {t('googleregistration.submit')}
                </Button>
            )}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={2000}
                onClose={handleSnackbarClose}
                message="Account created successfully! Redirecting to login..."
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
        </div>
    );
}

export default GRegistration;