import React, { useState,useEffect } from 'react';
import { format, startOfMonth, endOfMonth, eachDayOfInterval, addMonths, isSameDay, isBefore, isToday } from 'date-fns';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set } from 'firebase/database';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { hostConfig } from '../../../../config';
import axios from 'axios';
import BookingSuccessAlert from './BookedAlert';
import { useTranslation } from "react-i18next";


//this app is for live video chat.  It's sending a calendar modal to the patient.

const firebaseConfig = {
    apiKey: "AIzaSyAfrKlyWtplvug2Drol81m5pUTXwDdpEDw",
    authDomain: "ascre-65d72.firebaseapp.com",
    databaseURL: "https://ascre-65d72-default-rtdb.asia-southeast1.firebasedatabase.app", // Added database URL
    projectId: "ascre-65d72",
    storageBucket: "ascre-65d72.appspot.com",
    messagingSenderId: "243468257698",
    appId: "1:243468257698:web:2f9faeeff618bce38b4afb",
    measurementId: "G-H5YNGZ8LKT"
};

// Initialize Firebase with the configuration
const app = initializeApp(firebaseConfig);

// Get a reference to the database service
const database = getDatabase(app);


const ModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,

};

const InVideoCalendarModal = (therapist_uuid) => {
    const today = new Date();
    const [currentMonth, setCurrentMonth] = useState(today);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState('');
    const [open, setOpen] = useState(true);

    const { t,i18n } = useTranslation();
    const handleClose = () => setOpen(false);
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(currentMonth);
    const daysInMonth = eachDayOfInterval({ start: monthStart, end: monthEnd });
    const [therapistData, setTherapistData] = useState([]);
    console.log("therapist_uuid in calendarmodal", therapist_uuid);

//for whatever fucking reason, if you take this list out the whole thing doesn't work.
    /*const reservedDatesList = [
        { date: new Date(2023, 12, 31), time: '10:00' },
        { date: new Date(2024, 1, 1), time: '14:00' },
        { date: new Date(2024, 1, 1), time: '9:00' },
        { date: new Date(2023, 12, 29), time: '15:00' },
        { date: new Date(2023, 12, 30), time: '12:00' },
        { date: new Date(2023, 12, 30), time: '13:00' },
        { date: new Date(2023, 12, 30), time: '14:00' },
        { date: new Date(2023, 12, 28), time: '12:00' },
    ];
   */
//local environment should be array result [0] and production is data.booked_dates
    useEffect(() => {
    
        const fetchData = async () => {
            try {
                const response = await axios.get(`${hostConfig.API_BASE}/therapistavailability`, {
                    params: { therapist_uuid }
                  });
                // Handle the response data
               //forproduction
               // setTherapistData(response.data.booked_dates);
                //for dev
                setTherapistData(response.data[0].booked_dates);
                console.log(hostConfig.API_BASE, "hostconfig");
                console.log(response.data, "response.data");
                console.log(response.data[0].booked_dates, "response.dat[0].booked_dates");
                console.log(response.data.booked_dates, "response.data.booked_dates");
                
               // console.log(response.data[0].booked_dates, "response.data[0].booked_dates");



            } catch (error) {
                // Handle the error
                console.error("Error fetching therapist data:", error);
            }
        };
    
        fetchData();
        
    }, [therapist_uuid]);

    const convertDateStrings = (dateStrings) => {
        return dateStrings.map(dateTimeString => {
            const [datePart, timePart] = dateTimeString.split(' ');
            const [year, month, day] = datePart.split('-').map(Number);
    
            // Adjust the month to be zero-indexed
            const adjustedMonth = month - 1;
    
            // Return the formatted object
            return {
                date: new Date(year, adjustedMonth, day),
                time: timePart.substring(0, 5) // Assuming you only need HH:MM
            };
        });
    };
         
        const converted_Dates = convertDateStrings(therapistData);
        console.log(converted_Dates, "converted dates");
        
        
    

    console.log(therapistData, "therapistData");
    console.log(therapist_uuid, "therapist_uuid");
    console.log(therapist_uuid.therapistUuid, "therapist_uuid.therapistUuid");
    const uuidTherapist = therapist_uuid.therapistUuid;
    console.log(uuidTherapist,"uuidtherapist");


    const reservedDates = converted_Dates;
        

      //  const reservedDatesTest = therapistData;
        console.log(reservedDates, "testing reserdDates");
        //console.log(reservedDatesTest, "testing converdatestrings");

    const isTimeReservedForDate = (date, time) => {
        return reservedDates.some(reservation => {
            // Directly adjust the month of the reservation date for comparison
            const adjustedReservationDate = new Date(
                reservation.date.getFullYear(),
                reservation.date.getMonth() , // Adjust month here
                reservation.date.getDate()
            );
    
            const formattedReservationDate = format(adjustedReservationDate, 'yyyy-MM-dd');
            const formattedSelectedDate = format(date, 'yyyy-MM-dd');
    
            console.log(`Comparing: ${formattedReservationDate} with ${formattedSelectedDate} and times: ${reservation.time} with ${time}`);
    
            return formattedReservationDate === formattedSelectedDate && reservation.time === time;
        });
    };
    
    
    const handleReservationSubmit = async () => {
    // Combine selectedDate and selectedTime into a single Date object
    const [hours, minutes] = selectedTime.split(':').map(Number);
    const reservationDateTime = new Date(selectedDate);
    reservationDateTime.setHours(hours, minutes, 0, 0);
    const formattedReservationDateTime = reservationDateTime.toISOString();
    console.log("Formatted reservation date time:", formattedReservationDateTime);
    const therapist_uuid = uuidTherapist;

    // Convert to timestamp (milliseconds since Unix Epoch)
    //const reservationTimestamp = reservationDateTime.getTime();
    const booking_date = formattedReservationDateTime;
    try {
        const response = await axios.post(`${hostConfig.API_BASE}/bookings`, {booking_date, therapist_uuid});

        // Handle successful response
        console.log('Reservation successful:', response.data);
        if (response.data.session_booked === true) {
            // Close the modal
            handleClose();
            // Show success alert
            <BookingSuccessAlert />;

            // Clear the specific session thread in Firebase
            const session_uuid = localStorage.getItem('session_uuid'); // Retrieve session UUID
            console.log('session_uuid',session_uuid);
            if (session_uuid) {
                await set(ref(database, `session/${session_uuid}`), null); // Clear the session data
                console.log("Session data cleared in Firebase");
            }
        }
    } catch (error) {
        // Handle error
        console.error('Error making reservation or clearing session:', error);
    }
    };


    const handleDateClick = (date) => {
        setSelectedDate(date);
        setSelectedTime(''); // Reset time when a new date is selected
    };

    const handleTimeClick = (time) => {
        setSelectedTime(time);
    }

    const handleMonthChange = (direction) => {
        setCurrentMonth(prevMonth => addMonths(prevMonth, direction));
    }

    return (

        <div className="reservation">

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={ModalStyle}>

                    <h2>{t('patientvideo.calendarmodal.title')}</h2>

                    <div className="month-navigation">
                        <button onClick={() => handleMonthChange(-1)}>←</button>
                        <h3>{format(currentMonth, 'MMMM yyyy')}</h3>
                        <button onClick={() => handleMonthChange(1)}>→</button>
                    </div>

                    <div className="ModalCalendar">
                        {daysInMonth.map((date) => (
                            <button
                                key={format(date, 'yyyy-MM-dd')}
                                onClick={() => handleDateClick(date)}
                                className={isSameDay(selectedDate, date) ? 'selected' : (isBefore(date, today) ? 'disabled' : '')}
                                disabled={isBefore(date, today)}
                            >
                                {format(date, 'd')}
                            </button>
                        ))}
                    </div>

                    {selectedDate && (
                        <div className="time-slots">
                            {['9:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00']
                                .filter(slot => {
                                    const isReserved = isTimeReservedForDate(selectedDate, slot);
                                    console.log(`Checking time ${slot} on ${format(selectedDate, 'yyyy-MM-dd')}: ${isReserved ? 'RESERVED' : 'AVAILABLE'}`);
                                    console.log(selectedDate, "checking selectedDate");
                                    console.log(slot, "checking slot");
                                    console.log(isReserved, "checking isReserved");
                                    return !isReserved;
                                })
                                .map(slot => (
                                    <button
                                        key={slot}
                                        onClick={() => handleTimeClick(slot)}
                                        className={selectedTime === slot ? 'selected' : ''}
                                    >
                                        {slot}
                                    </button>
                                ))}
                        </div>
                    )}

                    <button 
                    className="reserve-button"
                    onClick={handleReservationSubmit}
                    >
                        {selectedDate && selectedTime ? `${t('patientvideo.calendarmodal.button')} ${format(selectedDate, 'MMMM d, yyyy')} ${selectedTime}` : `${t('patientvideo.calendarmodal.buttonother')}`}
                    </button>
                </Box>
            </Modal>

        </div>
    );
}

export default InVideoCalendarModal;
 
