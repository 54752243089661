import React, { useEffect, useState, useRef } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import axios from "axios";
import { IconButton } from '@mui/material';
import { Mic, MicOff, Videocam, VideocamOff, Call, Schedule, PlayArrow, Pause,TextSnippet } from '@mui/icons-material';
import ScheduleSetterButton from './Scheduler/SchedulerButton';
import CalendarSender from './CalendarRequest/RequestSender';
import { hostConfig } from '../../config';

const appId = "5c0757f9509447ec80344d6b2557262d";
const testtoken = process.env.agoratesttoken;
const channel = 'AscreTestVideo';

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

const VideoChatPagetest = ({ test }) => {
    const [localVideoTrack, setLocalVideoTrack] = useState(null);
    const [localAudioTrack, setLocalAudioTrack] = useState(null);
    const [joinState, setJoinState] = useState(false);
    const [remoteUsers, setRemoteUsers] = useState([]);
    const [cameraState, setCameraState] = useState('initializing');
    const [videoDevices, setVideoDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState('');
    const [notes, setNotes] = useState('');
    const [error, setError] = useState('');
    const [isNotesVisible, setIsNotesVisible] = useState(false);
    const [isAudioMuted, setIsAudioMuted] = useState(false);
    const [isVideoMuted, setIsVideoMuted] = useState(false);
    const [calling, setCalling] = useState(false);
    
    // Timer states
    const initialMinutes = 60;
    const [minutes, setMinutes] = useState(initialMinutes);
    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);
  
    const localVideoRef = useRef(null);
    const remoteVideoRef = useRef(null);
  
    useEffect(() => {
      const init = async () => {
        try {
          const devices = await AgoraRTC.getCameras();
          setVideoDevices(devices);
          if (devices.length > 0) {
            setSelectedDeviceId(devices[0].deviceId);
          }
        } catch (error) {
          console.error("Error getting camera devices:", error);
          setCameraState('error');
          setError('Failed to get camera devices. Please check your permissions.');
        }
      };
  
      init();
    }, []);
  
    useEffect(() => {
      const initializeAgora = async () => {
        if (!appId || !channel) {
          setError('Agora App ID or channel name is missing. Please check your configuration.');
          return;
        }
  
        client.on('user-published', handleUserPublished);
        client.on('user-unpublished', handleUserUnpublished);
  
        try {
          await client.join(appId, channel, testtoken || null, null);
          const audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
          const videoTrack = await AgoraRTC.createCameraVideoTrack({
            deviceId: selectedDeviceId
          });
          
          setLocalAudioTrack(audioTrack);
          setLocalVideoTrack(videoTrack);
          setJoinState(true);
          setCameraState('ready');
  
          await client.publish([audioTrack, videoTrack]);
  
          if (localVideoRef.current) {
            videoTrack.play(localVideoRef.current);
          }
        } catch (error) {
          console.error("Error joining channel or creating tracks:", error);
          setCameraState('error');
          setError(`Failed to join channel: ${error.message}`);
        }
      };
  
      if (selectedDeviceId) {
        initializeAgora();
      }
  
      return () => {
        if (localAudioTrack) {
          localAudioTrack.stop();
          localAudioTrack.close();
        }
        if (localVideoTrack) {
          localVideoTrack.stop();
          localVideoTrack.close();
        }
        client.removeAllListeners();
        client.leave();
      };
    }, [selectedDeviceId, appId, channel, testtoken]);
  
    useEffect(() => {
      let interval;
      if (isActive && (minutes > 0 || seconds > 0)) {
        interval = setInterval(() => {
          if (seconds > 0) {
            setSeconds(seconds => seconds - 1);
          } else if (minutes > 0) {
            setMinutes(minutes => minutes - 1);
            setSeconds(59);
          }
        }, 1000);
      } else if (!isActive || (minutes === 0 && seconds === 0)) {
        setIsActive(false);
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }, [isActive, minutes, seconds]);
  
    const handleUserPublished = async (user, mediaType) => {
      await client.subscribe(user, mediaType);
      if (mediaType === 'video') {
        setRemoteUsers(prevUsers => [...prevUsers, user]);
        user.videoTrack.play(remoteVideoRef.current);
      }
      if (mediaType === 'audio') {
        user.audioTrack.play();
      }
    };
  
    const handleUserUnpublished = (user) => {
      setRemoteUsers(prevUsers => prevUsers.filter(u => u.uid !== user.uid));
    };
  
    const toggleAudio = () => {
      if (localAudioTrack) {
        localAudioTrack.setEnabled(!isAudioMuted);
        setIsAudioMuted(!isAudioMuted);
      }
    };
  
    const toggleVideo = async () => {
      if (localVideoTrack) {
        if (isVideoMuted) {
          await localVideoTrack.setEnabled(true);
          if (localVideoRef.current) {
            localVideoTrack.play(localVideoRef.current);
          }
        } else {
          await localVideoTrack.setEnabled(false);
        }
        setIsVideoMuted(!isVideoMuted);
      }
    };
  
    const handleHangUp = async () => {
      setCalling(prevCalling => !prevCalling);
      const session_uuid = localStorage.getItem('session_uuid');
      
      if (calling) {
        try {
          const response = await axios.post(`${hostConfig.API_BASE}/therapistleaveroom`, 
          {session_uuid:session_uuid });
          console.log(response.data);
        } catch (error) {
          console.error('Hang up error:', error);
        }
      }
    };
  
    const toggleTimer = () => setIsActive(!isActive);
  
    const formatTime = (minutes, seconds) => {
      return `${minutes}:${seconds.toString().padStart(2, '0')}`;
    };
  
    const handleNotesChange = (event) => {
      setNotes(event.target.value);
    };
  
    const toggleNotes = () => {
      setIsNotesVisible(!isNotesVisible);
    };
  
    return (
      <div style={{ position: 'relative', width: '100vw', height: '100vh', overflow: 'hidden' }}>
        {error && (
          <div style={{ position: 'absolute', top: 0, left: 0, right: 0, backgroundColor: 'red', color: 'white', padding: '10px', textAlign: 'center', zIndex: 1000 }}>
            {error}
          </div>
        )}
        
        {/* Remote Video (Full Screen) */}
        <div ref={remoteVideoRef} style={{ width: '100%', height: '100%', objectFit: 'cover', backgroundColor: '#000' }} />
        
        {/* Local Video (Bottom Left) */}
        <div style={{ position: 'absolute', bottom: 80, left: 20, width: '200px', height: '150px', backgroundColor: '#ddd', overflow: 'hidden' }}>
          {cameraState === 'ready' ? (
            <div ref={localVideoRef} style={{ width: '100%', height: '100%', display: isVideoMuted ? 'none' : 'block' }} />
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: '10px', textAlign: 'center' }}>
              {cameraState === 'initializing' ? 'Initializing camera...' : 'Camera error. Please check permissions.'}
            </div>
          )}
          {isVideoMuted && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', backgroundColor: '#333', color: 'white' }}>
              Camera Off
            </div>
          )}
        </div>
        
        {/* Bottom Control Bar */}
        <div style={{ 
          position: 'absolute', 
          bottom: 0, 
          left: 0, 
          right: 0, 
          padding: '10px', 
          backgroundColor: 'rgba(0,0,0,0.5)', 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center',
          gap: '10px'
        }}>
          <IconButton onClick={toggleAudio} color="primary">
            {isAudioMuted ? <MicOff /> : <Mic />}
          </IconButton>
          <IconButton onClick={toggleVideo} color="primary">
            {isVideoMuted ? <VideocamOff /> : <Videocam />}
          </IconButton>
          <IconButton
            onClick={handleHangUp}
            color={calling ? "secondary" : "primary"}
          >
            <Call />
          </IconButton>
          <IconButton onClick={toggleTimer} color="primary">
            {isActive ? <Pause /> : <PlayArrow />}
          </IconButton>
          <span style={{ color: 'white', marginRight: '10px' }}>{formatTime(minutes, seconds)}</span>
          <IconButton color="primary">
            <ScheduleSetterButton />
          </IconButton>
          <IconButton color="primary">
            <CalendarSender />
          </IconButton>
          <IconButton onClick={toggleNotes} color="primary">
            <TextSnippet />
          </IconButton>
        </div>
        
        {/* Notes Section (Right Side) */}
        <div style={{
          position: 'absolute',
          top: 0,
          right: isNotesVisible ? 0 : '-300px',
          width: '300px',
          height: '100%',
          backgroundColor: 'rgba(255,255,255,0.9)',
          transition: 'right 0.3s ease-in-out',
          display: 'flex',
          flexDirection: 'column',
          padding: '20px',
          boxSizing: 'border-box'
        }}>
          <h3>Notes</h3>
          <textarea
            value={notes}
            onChange={handleNotesChange}
            style={{ flex: 1, width: '100%', padding: '10px', boxSizing: 'border-box', resize: 'none' }}
            placeholder="Type your notes here..."
          />
        </div>
      </div>
    );
  };
  
  export default VideoChatPagetest;