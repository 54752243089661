import React from 'react';
import { useTranslation } from "react-i18next";

const CountdownTimer = ({ minutes, seconds }) => {
  const { t,i18n } = useTranslation();
  return (
    <div className="time">
      {minutes}{t('therapistvideochat.note.timer.minute')} : {seconds < 10 ? `0${seconds}` : seconds}{t('therapistvideochat.note.timer.seconds')}
    </div>
  );
}

export default CountdownTimer;
