import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { Container, Box, Button, Typography, CircularProgress, TextField } from '@mui/material';
import { hostConfig } from '../../../config';
import WithdrawalForm from './withdrawal';
import TransactionHistory from './TransactionHistory';

const stripePromise = loadStripe('pk_test_51P74pVIQ06ve5VRvGs2NtWKfCzpLEQGzZXoyTFlz33ZOoMI1PdFMlldv9FeTFBGAcab4vAIHw4l6MECjXnPhea7Y00zYON4Cge');

const TherapistPaymentApp = () => {
  const [accountStatus, setAccountStatus] = useState('loading');
  const [createAccountLoading, setCreateAccountLoading] = useState(false);
  const [email, setEmail] = useState('');

  useEffect(() => {
    const checkStripeAccount = async () => {
      try {
        const response = await axios.get(`${hostConfig.API_BASE}/check-stripe-account`);
        setAccountStatus(response.data.status);
      } catch (error) {
        console.error('Error checking Stripe account:', error);
        setAccountStatus('error');
      }
    };

    checkStripeAccount();
  }, []);

  const handleCreateStripeAccount = async (event) => {
    event.preventDefault();
    setCreateAccountLoading(true);
    try {
      const response = await axios.post(`${hostConfig.API_BASE}/manage-stripe-account`, { email });
      if (response.data.accountLink) {
        window.location.href = response.data.accountLink;
      } else {
        console.error('No account link received');
        setAccountStatus('error');
      }
    } catch (error) {
      console.error('Error creating Stripe account:', error);
      setAccountStatus('error');
    } finally {
      setCreateAccountLoading(false);
    }
  };

  const renderContent = () => {
    switch (accountStatus) {
      case 'loading':
        return <CircularProgress />;
      case 'no_account':
        return (
          <Container maxWidth="sm">
            <Typography variant="h6" gutterBottom>
              You need to create a Stripe account to manage payments.
            </Typography>
            <Box component="form" onSubmit={handleCreateStripeAccount} sx={{ mt: 2 }}>
              <TextField
                label="Email"
                type="email"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                margin="normal"
              />
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={createAccountLoading}
                sx={{ mt: 2 }}
              >
                {createAccountLoading ? <CircularProgress size={24} /> : 'Create Stripe Account'}
              </Button>
            </Box>
          </Container>
        );
      case 'no_bank_account':
        return (
          <Container maxWidth="sm">
            <Typography variant="h6" color="error" gutterBottom>
              You cannot withdraw payments until a bank account is connected.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.location.href = '/connect-bank-account'}
            >
              Connect Bank Account
            </Button>
          </Container>
        );
      case 'ready':
        return (
          <>
            <WithdrawalForm />
            <TransactionHistory />
          </>
        );
      case 'error':
        return (
          <Typography variant="h6" color="error">
            An error occurred while checking your account status. Please try again later.
          </Typography>
        );
      default:
        return null;
    }
  };

  return (
    <Elements stripe={stripePromise}>
      <Box sx={{ mt: 4 }}>
        {renderContent()}
      </Box>
    </Elements>
  );
};

export default TherapistPaymentApp;