import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
//import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import App from "./App";
import AgoraRTC from "agora-rtc-sdk-ng";
import { AgoraRTCProvider } from "agora-rtc-react";
import i18n from "./i18n";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);





const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });
// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


// Initialize Firebase




root.render(
  <GoogleOAuthProvider clientId="89676155600-b024scjh0qd25mp96ce1mie6h9nqtdcb.apps.googleusercontent.com">

  
  <AgoraRTCProvider client={client}>
    <App />
    </AgoraRTCProvider>
    
  
  </GoogleOAuthProvider>,

);
