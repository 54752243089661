import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { hostConfig } from '../../../config';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

const Therapist_ClientList = () => {
  const { t } = useTranslation();
  const [therapists, setTherapists] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${hostConfig.API_BASE}/clientlist`);
        setTherapists(response.data);
      } catch (error) {
        console.error('Error fetching client data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSessionJoin = async (session_uuid) => {
    try {
      const response = await axios.post(`${hostConfig.API_BASE}/therapistjoinroom`, { session_uuid });
      console.log('Session join successful', response.data);
    } catch (error) {
      console.error('Error joining session:', error);
    }
  };

  const columns = [
    { field: 'patient_full_name', headerName: t('therapistdashboard.clientlist.patientname'), width: 200 },
    { field: 'booked_session_date_timestamp', headerName: t('therapistdashboard.clientlist.nextreservation'), width: 200 },
    { field: 'total_sessions', headerName: t('therapistdashboard.clientlist.numberofreservation'), width: 200 },
    { field: 'first_session_date', headerName: t('therapistdashboard.clientlist.firstsessiondate'), width: 200 },
    { 
      field: 'subscription', 
      headerName: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {t('therapistdashboard.clientlist.subscription')}
          <Tooltip title="This button will create a subscription for your patient" arrow>
            <InfoIcon style={{ fontSize: 16, marginLeft: 4, color: 'rgba(0, 0, 0, 0.54)' }} />
          </Tooltip>
        </div>
      ),
      renderCell: (params) => (
        <Link to={`/therapist/videochat/${params.row.session_uuid}`} state={{
          token: params.row.meeting_token,
          channel: params.row.session_uuid,
          uid: ''
        }}>
          <Button
            onClick={() => handleSessionJoin(params.row.session_uuid)}
            variant="contained"
            color="primary"
            size="small"
          >
            {t('therapistdashboard.clientlist.subscription')}
          </Button>
        </Link>
      ),
      width: 150
    },
    {
      field: 'actions',
      headerName: t('therapistdashboard.clientlist.jointitle'),
      renderCell: (params) => (
        <Link to={`/therapist/videochat/${params.row.session_uuid}`} state={{
          token: params.row.meeting_token,
          channel: params.row.session_uuid,
          uid: ''
        }}>
          <Button
            onClick={() => handleSessionJoin(params.row.session_uuid)}
            variant="contained"
            color="primary"
          >
            {t('therapistdashboard.clientlist.joinbutton')}
          </Button>
        </Link>
      ),
      width: 150
    },
  ];

  return (
    <div className="therapist-clientlist">
      <div className="data-grid-container" style={{ height: 400, width: 1000 }}>
        <DataGrid
          rows={therapists.map((therapist, index) => ({
            id: index,
            ...therapist
          }))}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
        />
      </div>
    </div>
  );
}

export default Therapist_ClientList;