import React from 'react';
import { useTranslation } from "react-i18next";

const ResetButton = ({ resetTimer }) => {
  const { t,i18n } = useTranslation();
  return (
    <button className="TimerReset" onClick={resetTimer}>
      {t('therapistvideochat.note.timer.resetbutton')}
    </button>
  );
}

export default ResetButton;
