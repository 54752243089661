import React, { useState, useEffect, useRef } from 'react';
import AgoraRTC from 'agora-rtc-sdk-ng';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { Mic, MicOff, Videocam, VideocamOff, CallEnd, TextSnippet } from '@mui/icons-material';
import Receiver from './CalendarRequestPopup/CalendarReceiver';
import { hostConfig } from '../../../config';
import './VideoChatPage.css';

const appId = "5c0757f9509447ec80344d6b2557262d";

const PatientVideoV2 = () => {
  const [client] = useState(() => AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' }));
  const [localAudioTrack, setLocalAudioTrack] = useState(null);
  const [localVideoTrack, setLocalVideoTrack] = useState(null);
  const [start, setStart] = useState(false);
  const [remoteUsers, setRemoteUsers] = useState({});
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isVideoMuted, setIsVideoMuted] = useState(false);
  const [isNotesVisible, setIsNotesVisible] = useState(false);
  const [notes, setNotes] = useState('');
  const [error, setError] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const localVideoRef = useRef(null);
  const remoteVideoRefs = useRef({});

  const token = location.state?.meeting_token;
  const channelName = location.state?.session_uuid;

  useEffect(() => {
    client.on("user-published", handleUserPublished);
    client.on("user-unpublished", handleUserUnpublished);
    client.on("user-left", handleUserLeft);

    const joinChannel = async () => {
      if (!start && token && channelName) {
        try {
          await client.join(appId, channelName, token, null);

          const microphoneTrack = await AgoraRTC.createMicrophoneAudioTrack();
          const cameraTrack = await AgoraRTC.createCameraVideoTrack();
          setLocalAudioTrack(microphoneTrack);
          setLocalVideoTrack(cameraTrack);

          if (localVideoRef.current) {
            cameraTrack.play(localVideoRef.current);
          }
          await client.publish([microphoneTrack, cameraTrack]);

          setStart(true);
        } catch (error) {
          console.error("Error joining Agora channel:", error);
          setError("Failed to join the video call. Please try again.");
        }
      }
    };

    joinChannel();

    return () => {
      client.off("user-published", handleUserPublished);
      client.off("user-unpublished", handleUserUnpublished);
      client.off("user-left", handleUserLeft);
    };
  }, [client, token, channelName, start]);

  const handleUserPublished = async (user, mediaType) => {
    await client.subscribe(user, mediaType);
    setRemoteUsers((prevUsers) => ({
      ...prevUsers,
      [user.uid]: { ...prevUsers[user.uid], [mediaType]: user[`${mediaType}Track`] },
    }));

    if (mediaType === 'video' && remoteVideoRefs.current[user.uid]) {
      user.videoTrack.play(remoteVideoRefs.current[user.uid]);
    }
    if (mediaType === 'audio') {
      user.audioTrack.play();
    }
  };

  const handleUserUnpublished = (user, mediaType) => {
    setRemoteUsers((prevUsers) => {
      const updatedUser = { ...prevUsers[user.uid] };
      delete updatedUser[mediaType];
      return { ...prevUsers, [user.uid]: updatedUser };
    });
  };

  const handleUserLeft = (user) => {
    setRemoteUsers((prevUsers) => {
      const updatedUsers = { ...prevUsers };
      delete updatedUsers[user.uid];
      return updatedUsers;
    });
  };

  const toggleAudio = () => {
    if (localAudioTrack) {
      localAudioTrack.setEnabled(!isAudioMuted);
      setIsAudioMuted(!isAudioMuted);
    }
  };

  const toggleVideo = async () => {
    if (localVideoTrack) {
      if (isVideoMuted) {
        // If video is currently muted, create a new video track
        const newVideoTrack = await AgoraRTC.createCameraVideoTrack();
        await client.publish(newVideoTrack);
        setLocalVideoTrack(newVideoTrack);
        if (localVideoRef.current) {
          newVideoTrack.play(localVideoRef.current);
        }
      } else {
        // If video is currently unmuted, stop and close the track
        localVideoTrack.stop();
        await client.unpublish(localVideoTrack);
        localVideoTrack.close();
      }
      setIsVideoMuted(!isVideoMuted);
    }
  };

  const leaveChannel = async () => {
    if (start) {
      if (localAudioTrack) {
        localAudioTrack.stop();
        localAudioTrack.close();
        setLocalAudioTrack(null);
      }
      if (localVideoTrack) {
        localVideoTrack.stop();
        localVideoTrack.close();
        setLocalVideoTrack(null);
      }

      await client.unpublish();
      await client.leave();

      setStart(false);
      setRemoteUsers({});
      setIsVideoMuted(true);
      setIsAudioMuted(true);

      try {
        const session_uuid = localStorage.getItem('session_uuid');
        await axios.post(`${hostConfig.API_BASE}/patientleaveroom`, {
          timestamp: new Date().toISOString(),
          session_uuid: session_uuid
        });
      } catch (error) {
        console.error('Error sending leave channel notification:', error);
      }

      localStorage.removeItem('therapist_uuid');
      localStorage.removeItem('session_uuid');
      navigate('/patientdashboard');
    }
  };

  return (
    <div style={{ position: 'relative', width: '100vw', height: '100vh', overflow: 'hidden' }}>
      {error && (
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, backgroundColor: 'red', color: 'white', padding: '10px', textAlign: 'center', zIndex: 1000 }}>
          {error}
        </div>
      )}
      
      {/* Remote Video (Full Screen) */}
      <div className="patient-remote-users" style={{ width: '100%', height: '100%', backgroundColor: '#000' }}>
        {Object.entries(remoteUsers).map(([uid, user]) => (
          <div key={uid} style={{ width: '100%', height: '100%' }}>
            {user.video && (
              <div ref={(el) => (remoteVideoRefs.current[uid] = el)} style={{ width: '100%', height: '100%' }} />
            )}
          </div>
        ))}
      </div>
      
      {/* Local Video (Bottom Left) */}
      <div ref={localVideoRef} style={{
        position: 'absolute', 
        bottom: 80, 
        left: 20, 
        width: '160px', 
        height: '120px', 
        backgroundColor: '#ddd',
        borderRadius: '15px',
        overflow: 'hidden'
      }}></div>
      
      {/* Bottom Control Bar */}
      <div style={{ 
        position: 'absolute', 
        bottom: 0, 
        left: 0, 
        right: 0, 
        padding: '10px', 
        backgroundColor: 'rgba(0,0,0,0.5)', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        gap: '10px'
      }}>
        <IconButton onClick={toggleAudio} style={{ backgroundColor: isAudioMuted ? 'red' : '#0A6621', color: 'white' }}>
          {isAudioMuted ? <MicOff /> : <Mic />}
        </IconButton>
        <IconButton onClick={toggleVideo} style={{ backgroundColor: isVideoMuted ? 'red' : '#0A6621', color: 'white' }}>
          {isVideoMuted ? <VideocamOff /> : <Videocam />}
        </IconButton>
        <IconButton onClick={leaveChannel} style={{ backgroundColor: 'red', color: 'white' }}>
          <CallEnd />
        </IconButton>
        <Receiver />
      </div>
    </div>
  );
};

export default PatientVideoV2;