import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, set,onValue } from 'firebase/database';
//import Reservation from '../../../../TherapistApps/VideoChat/CalendarRequest/CalendarRequest';
import InVideoCalendarModal from './CalendarModal';


// Define your Firebase configuration first
const firebaseConfig = {
    apiKey: "AIzaSyAfrKlyWtplvug2Drol81m5pUTXwDdpEDw",
    authDomain: "ascre-65d72.firebaseapp.com",
    databaseURL: "https://ascre-65d72-default-rtdb.asia-southeast1.firebasedatabase.app", // Added database URL
    projectId: "ascre-65d72",
    storageBucket: "ascre-65d72.appspot.com",
    messagingSenderId: "243468257698",
    appId: "1:243468257698:web:2f9faeeff618bce38b4afb",
    measurementId: "G-H5YNGZ8LKT"
};

// Initialize Firebase with the configuration
const app = initializeApp(firebaseConfig);
// Get a reference to the database service
const database = getDatabase(app);


function Receiver() {
    const [receivedMessage, setReceivedMessage] = useState('');
    const [therapistUuid,setTherapistUuid] = useState('');
    //const session_uuid = localStorage.getItem('session_uuid');
    //const session_uuid = 'some random value';
  
    useEffect(() => {
      // Retrieve session_uuid from localStorage
      const session_uuid = localStorage.getItem('session_uuid');

      if (session_uuid) {
          // Only proceed if session_uuid is available
          const messageRef = ref(database, `session/${session_uuid}`);
          const unsubscribe = onValue(messageRef, (snapshot) => {
              if (snapshot.exists()) {
                  const data = snapshot.val();
                  setTherapistUuid(data.therapist_uuid); // Assuming the UUID is in data.therapist_uuid
                  setReceivedMessage(data.message);
              } else {
                  console.log('No data available for session_uuid:', session_uuid);
              }
          });

          // Cleanup
          return () => unsubscribe();
      } else {
          console.log('No session_uuid found in localStorage');
      }
  }, [database]);
    
      return (
        <div>
          {receivedMessage ? 
          <div>
          <InVideoCalendarModal therapistUuid = {therapistUuid}/>
          </div>
           : 
           <p></p>}
        </div>
      );
  }
  
  export default Receiver;

  //<p>Message: {receivedMessage}</p>