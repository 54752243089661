import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import AgoraRTC from 'agora-rtc-sdk-ng';
import {
    useJoin,
    useLocalMicrophoneTrack,
    useLocalCameraTrack,
    usePublish,
    useRemoteUsers,
    useIsConnected 
} from "agora-rtc-react";
import { UserVideos } from "./VideoControl/Video";
import { VideoControls } from "./VideoControl/VideoControl";
import CountdownTimer from "./Timer/CoundownTimer";
import ResetButton from "./Timer/ResetButton";
import TimeControlButtons from "./Timer/TimerStartButton";
import TherapistNotes from "./Notes/TherapistNotes"
import CalendarSender from './CalendarRequest/RequestSender';
import { useTranslation } from "react-i18next";


export const VideoChatPage = () => {
    const { uuid } = useParams(); // Retrieve the channel name from URL
    const location = useLocation();
    const { token, channel, uid } = location.state || {};
    const { t,i18n } = useTranslation();
    const [appId] = useState("5c0757f9509447ec80344d6b2557262d"); // Set your actual App ID here
    //const [channel, setChannel] = useState(uuid);
    //const [token, setToken] = useState(sessionDetails?.token);
    //const [uid] = useState(sessionDetails?.uid || 0); // Default to 0 if uid is not provided

    const [calling, setCalling] = useState(false);
    const isConnected = useIsConnected();
    const initialMinutes = 60;
    const [minutes, setMinutes] = useState(initialMinutes);
    const [seconds, setSeconds] = useState(0);
    const [isActive, setIsActive] = useState(false);

    useJoin({ appid: appId, channel, token }, calling);
    const [micOn, setMic] = useState(true);
    const [cameraOn, setCamera] = useState(true);
    const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
    const { localCameraTrack } = useLocalCameraTrack(cameraOn);
    usePublish([localMicrophoneTrack, localCameraTrack]);
    const remoteUsers = useRemoteUsers();

    console.log("Loading... - Current location", location.state);

    useEffect(() => {

        if (appId && channel && token) {
            setCalling(true);
        }
    }, [appId, channel, token]);

    useEffect(() => {
        let interval;
        if (isActive && (minutes > 0 || seconds > 0)) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds > 0 ? seconds - 1 : 59);
                setMinutes(minutes => seconds === 0 ? minutes - 1 : minutes);
            }, 1000);
        } else if (!isActive || (minutes === 0 && seconds === 0)) {
            setIsActive(false);
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isActive, seconds, minutes]);

    const resetTimer = () => {
        setIsActive(false);
        setMinutes(initialMinutes);
        setSeconds(0);
    };

    return (
      <div className="room">
          
              <div className="VideoApp">
                  <div className="VideoBox">
                      <div className="GuestVideo">
                          <div className="GuestName"></div>
                          <UserVideos 
                              isConnected={isConnected}
                              localMicrophoneTrack={localMicrophoneTrack}
                              cameraOn={cameraOn}
                              micOn={micOn}
                              localCameraTrack={localCameraTrack}
                              remoteUsers={remoteUsers}
                          />
                      </div>
                      <div className="DoctorVideo"></div>
                  </div>
                  <div className="VideoButtons">
                      <VideoControls 
                          isConnected={isConnected}
                          calling={calling}
                          setMic={setMic}
                          micOn={micOn}
                          setCamera={setCamera}
                          cameraOn={cameraOn}
                          setCalling={setCalling}
                      />
                  </div>
                  <div className="DoctorTools">
                      <div className="DoctorNotes">{t('therapistvideochat.notes')}
                          <TherapistNotes 
                          isInCall={calling} 
                          channel = {channel}
                          />
                      </div>
                      <div className="SubTools">
                          <div className="TimerBox">
                          {t('therapistvideochat.sessiontimer')}
                              <h2><CountdownTimer minutes={minutes} seconds={seconds} /></h2>
                              <TimeControlButtons isActive={isActive} setIsActive={setIsActive} />
                              <ResetButton resetTimer={resetTimer} />
                          </div>
                          <div className="AppointmentButton">
                            <CalendarSender />

                          </div>
                      </div>
                  </div>
              </div>
         
            

              <div>
                {t('therapistvideochat.loading')}
                <p>{JSON.stringify(location.state, null, 2)}</p>
              </div> // Display loading or some placeholder content
              
          )}*/
      </div>
  );
  
};

export default VideoChatPage;
