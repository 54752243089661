import React, { useEffect, useState } from "react";
import axios from 'axios';
import { Link } from 'react-router-dom';
import { DataGrid } from '@mui/x-data-grid';
import { hostConfig } from '../../../config';
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';

const History = () => {
  const { t } = useTranslation();
  const [therapists, setTherapists] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${hostConfig.API_BASE}/clientlist`);
        setTherapists(response.data);
      } catch (error) {
        console.error('Error fetching client data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSessionJoin = async (session_uuid) => {
    try {
      const response = await axios.post(`${hostConfig.API_BASE}/therapistjoinroom`, { session_uuid });
      console.log('Session join successful', response.data);
    } catch (error) {
      console.error('Error joining session:', error);
    }
  };

  const columns = [
    { field: 'patient_full_name', headerName: t('therapistdashboard.history.customername'), width: 200 },
    { field: 'booked_session_date_timestamp', headerName: t('therapistdashboard.history.date'), width: 200 },
    { field: 'meeting_token', headerName: t('therapistdashboard.history.chargebutton'), width: 200 },
    { field: 'session_uuid', headerName: t('therapistdashboard.history.amountcharged'), width: 200 },
    { field: 'paid', headerName: t('therapistdashboard.history.paid'),
      renderCell: (params) => (
        <Link to={`/therapist/videochat/${params.row.session_uuid}`} state={{
          token: params.row.meeting_token,
          channel: params.row.session_uuid,
          uid: ''
        }}>
          <Button
            onClick={() => handleSessionJoin(params.row.session_uuid)}
            variant="contained"
            color="primary"
          >
            {t('therapistdashboard.history.paid')}
          </Button>
        </Link>
      )
    },
    { field: 'duration', headerName: t('therapistdashboard.history.duration'), width: 200 },
    { field: 'rating', headerName: t('therapistdashboard.history.rating'), width: 200 },
    {
      field: 'actions',
      headerName: t('therapistdashboard.clientlist.jointitle'),
      
      renderCell: (params) => (
        <Link to={`/therapist/videochat/${params.row.session_uuid}`} state={{
          token: params.row.meeting_token,
          channel: params.row.session_uuid,
          uid: ''
        }}>
          <Button
            onClick={() => handleSessionJoin(params.row.session_uuid)}
            variant="contained"
            color="primary"
          >
            {t('therapistdashboard.clientlist.joinbutton')}
          </Button>
        </Link>
      )
    },
  ];

  return (
<div className="therapist-clientlist">
  <div className="data-grid-container" style={{ height: 400, width: 1000 }}>
    <DataGrid
      rows={therapists.map((therapist, index) => ({
        id: index,
        ...therapist
      }))}
      columns={columns}
      pageSize={5}
      rowsPerPageOptions={[5]}
      checkboxSelection
      
    />
  </div>
</div>
  );
}

export default History;
