import React from 'react';
import { useTranslation } from "react-i18next";

const TimeControlButtons = ({ isActive, setIsActive }) => {
  const { t,i18n } = useTranslation();
  return (
    <button className="TimerControlButton" onClick={() => setIsActive(!isActive)}>
      {isActive ? t('therapistvideochat.note.timer.pause') : t('therapistvideochat.note.timer.startbutton')}
    </button>
  );
}

export default TimeControlButtons;
