import React, { useEffect, useState } from "react";
import axios from "axios";
import { hostConfig } from "../../config.js";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Button, Select, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useTranslation } from "react-i18next";

const PatientSchedule = () => {
  const [patientSchedules, setPatientSchedules] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [exchangeRates, setExchangeRates] = useState({});
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    async function fetchData() {
      try {
        const [schedulesResponse, ratesResponse] = await Promise.all([
          axios.get(`${hostConfig.API_BASE}/getpatientschedule`),
          axios.get('https://api.exchangerate-api.com/v4/latest/USD')
        ]);

        const sortedSchedules = schedulesResponse.data.sort((a, b) =>
          new Date(a.booked_session_date_timestamp) - new Date(b.booked_session_date_timestamp)
        );

        console.log("Sorted Schedules:", sortedSchedules);
        setPatientSchedules(sortedSchedules);
        setExchangeRates(ratesResponse.data.rates);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
    fetchData();
  }, []);

  const handleJoinMeeting = (meeting_tokens,session_uuid) => {
    localStorage.setItem('session_uuid', session_uuid);
    navigate(`/patient/room/${session_uuid}`, {
      state: {
        meeting_token: meeting_tokens,
        session_uuid: session_uuid,
      },
    });
  };

  const getCurrencyFromTimezone = (timezone) => {
    switch (timezone) {
      case 'Asia/Tokyo':
        return 'JPY';
      case 'Australia/Sydney':
        return 'AUD';
      case 'America/New_York':
      case 'America/Los_Angeles':
      case 'America/Chicago':
      case 'America/Denver':
        return 'USD';
      default:
        return 'USD';
    }
  };

  const convertCurrency = (rate, fromCurrency, toCurrency) => {
    if (fromCurrency === toCurrency) return rate;
    const usdRate = rate / exchangeRates[fromCurrency];
    return (usdRate * exchangeRates[toCurrency]).toFixed(2);
  };

  const formatHourlyRate = (rate) => {
    console.log("formatHourlyRate params:", { rate });
    if (rate === undefined) return 'N/A';
    // Assuming all rates are in JPY for now
    const fromCurrency = 'JPY';
    const convertedRate = convertCurrency(rate, fromCurrency, selectedCurrency);
    return `${convertedRate} ${selectedCurrency}`;
  };

  const columns = [
    { 
      field: 'booked_session_date_timestamp', 
      headerName: 'Date and Time', 
      width: 200, 
      valueGetter: (params) => {
        console.log("Date and Time params:", params);
        return params ? moment(params).format('YYYY-MM-DD HH:mm:ss z') : 'N/A';
      }
    },
    { field: 'full_name', headerName: 'Therapist Name', width: 200 },
    { 
      field: 'hourly_rate', 
      headerName: 'Hourly Rate', 
      width: 200, 
      valueGetter: (params) => {
        console.log("Hourly Rate params:", params);
        return formatHourlyRate(params);
      },
      renderHeader: (params) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <span>Hourly Rate</span>
          <Select
            value={selectedCurrency}
            onChange={(e) => setSelectedCurrency(e.target.value)}
            size="small"
            style={{ height: '25px' }}
          >
            <MenuItem value="USD">USD</MenuItem>
            <MenuItem value="JPY">JPY</MenuItem>
            <MenuItem value="AUD">AUD</MenuItem>
            <MenuItem value="SGD">SGD</MenuItem>
          </Select>
        </div>
      ),
    },
    {
      field: 'meeting_token',
      headerName: 'Join Session',
      width: 150,
      renderCell: (params) => {
        console.log("Join Session params:", params);
        return (
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={() => handleJoinMeeting(params.row.meeting_token,params.row.session_uuid)}
            disabled={!params.value}
          >
            Join
          </Button>
        );
      },
    },
  ];

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Upcoming Sessions
      </Typography>
      {patientSchedules.length > 0 ? (
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={patientSchedules}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5, 10, 20]}
            getRowId={(row) => row.session_uuid}
          />
        </div>
      ) : (
        <div>
          <Typography>No upcoming sessions scheduled.</Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate('/searchtherapist')}
          >
            Find a Therapist
          </Button>
        </div>
      )}
    </Container>
  );
};

export default PatientSchedule;