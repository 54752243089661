import React, { useState, useCallback, useRef, useEffect } from 'react';
import axios from 'axios';
import { 
  Button, 
  IconButton, 
  Dialog, 
  DialogContent, 
  DialogTitle, 
  DialogActions,
  Tooltip,
  TextField,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Switch,
  FormControlLabel
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { hostConfig } from '../../../config';

// Update this to your actual backend URL

const timezoneOptions = [
  { value: 'Asia/Tokyo', label: 'Japan' },
  { value: 'America/New_York', label: 'US Eastern' },
  { value: 'America/Chicago', label: 'US Central' },
  { value: 'America/Denver', label: 'US Mountain' },
  { value: 'America/Los_Angeles', label: 'US Pacific' },
];

const mockPatients = [
  { name: 'John Doe', uuid: 'mock-uuid-1' },
  { name: 'Jane Smith', uuid: 'mock-uuid-2' },
  { name: 'Alice Johnson', uuid: 'mock-uuid-3' }
];

const generateMockEvents = () => {
  const events = [];
  const startDate = moment().startOf('day');
  
  for (let i = 0; i < 5; i++) {
    const eventDate = startDate.clone().add(i, 'days');
    const patient = mockPatients[i % mockPatients.length];
    events.push({
      id: `mock-event-${i}`,
      title: `Session with ${patient.name}`,
      booked_session_date_timestamp: eventDate.hour(9 + i).toISOString(),
      patient_uuid: patient.uuid,
      patient_full_name: patient.name
    });
  }
  return events;
};

const CalendarScheduler = ({ isOpen, onClose }) => {
  const [events, setEvents] = useState([]);
  const [isEventModalOpen, setIsEventModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventTitle, setEventTitle] = useState('');
  const [eventStart, setEventStart] = useState('');
  const [currentWeekStart, setCurrentWeekStart] = useState(getSunday(new Date()));
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedTimezone, setSelectedTimezone] = useState(timezoneOptions[0].value);
  const [patients, setPatients] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState('');

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const hours = Array.from({ length: 14 }, (_, i) => i + 7); // 7 AM to 8 PM
  const gridRef = useRef(null);

  useEffect(() => {
    fetchTherapistData();
    fetchPatients();
  }, [selectedTimezone]);

  useEffect(() => {
    if (gridRef.current) {
      const scrollToRow = 2;
      const rowHeight = gridRef.current.scrollHeight / hours.length;
      gridRef.current.scrollTop = rowHeight * scrollToRow;
    }
  }, [isOpen]);

  const fetchTherapistData = async () => {
    setIsLoading(true);
    setError(null);

    try {
      let eventsData;
      if (process.env.NODE_ENV === 'development') {
        eventsData = generateMockEvents();
      } else {
        const response = await axios.get(`${hostConfig.API_BASE}/gettherapistschedule`);
        eventsData = response.data;
      }

      console.log('Received events data:', eventsData);

      if (!Array.isArray(eventsData)) {
        throw new Error(`Invalid data format received. Expected an array, got ${typeof eventsData}`);
      }

      const transformedEvents = eventsData.map(event => {
        if (!event || typeof event !== 'object') {
          console.warn('Invalid event object:', event);
          return null;
        }

        const eventDateTime = moment.utc(event.booked_session_date_timestamp).tz(selectedTimezone);
        return {
          ...event,
          id: event.id || event.session_uuid,
          title: event.title || `Session with ${event.first_name || 'Unknown'}`,
          start: eventDateTime.toDate(),
          day: eventDateTime.format('ddd'),
          date: eventDateTime.format('M/D/YYYY'),
          hour: eventDateTime.format('HH:mm'),
          startTime: eventDateTime.format('HH:mm'),
          endTime: eventDateTime.clone().add(1, 'hour').format('HH:mm'),
          patient_uuid: event.patient_uuid,
          patient_full_name: event.patient_full_name,
          originalTimestamp: event.booked_session_date_timestamp
        };
      }).filter(Boolean);

      console.log('Transformed events:', transformedEvents);

      setEvents(transformedEvents);
    } catch (error) {
      console.error('Error fetching therapist data:', error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchPatients = async () => {
    if (process.env.NODE_ENV === 'production') {
      try {
        const response = await axios.get(`${hostConfig.API_BASE}/clientlist`);
        const uniquePatients = response.data.reduce((acc, patient) => {
          if (!acc.some(p => p.uuid === patient.patient_uuid)) {
            acc.push({
              name: patient.patient_full_name,
              uuid: patient.patient_uuid
            });
          }
          return acc;
        }, []);
        setPatients(uniquePatients);
      } catch (error) {
        console.error('Error fetching patients:', error);
      }
    } else {
      setPatients(mockPatients);
    }
  };

  function getSunday(d) {
    d = new Date(d);
    const day = d.getDay();
    const diff = d.getDate() - day;
    return new Date(d.setDate(diff));
  }

  function formatDate(date) {
    return moment(date).format('MMM D');
  }

  const handleCellClick = (date, hour, isTopHalf) => {
    const slotDate = moment.tz(date, selectedTimezone).hour(hour).minute(isTopHalf ? 0 : 30);
    setSelectedEvent(null);
    setEventTitle('');
    setEventStart(slotDate.format('YYYY-MM-DDTHH:mm'));
    setSelectedPatient('');
    setIsEventModalOpen(true);
  };

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setEventTitle(event.title);
    setEventStart(moment(event.start).tz(selectedTimezone).format('YYYY-MM-DDTHH:mm'));
    setSelectedPatient(event.patient_uuid);
    setIsEventModalOpen(true);
  };

  const handleSaveEvent = useCallback(async () => {
    const localEventStart = moment.tz(eventStart, selectedTimezone);
    const utcEventStart = localEventStart.clone().utc();
  
    const baseEventData = {
      patient_uuid: selectedPatient,
      timezone: selectedTimezone,
    };
  
    try {
      let response;
      if (selectedEvent) {
        // Editing an existing event
        const updateEventData = {
          ...baseEventData,
          id: selectedEvent.id, // Changed from session_uuid to id
          date: localEventStart.format('MM/DD/YYYY'),
          startTime: localEventStart.format('HH:mm'),
        };
        console.log(updateEventData,"edit event data");
        response = await axios.put(`${hostConfig.API_BASE}/edittherapistschedule`, updateEventData);
      } else {
        // Creating a new event
        const createEventData = {
          ...baseEventData,
          startTime: utcEventStart.format(), // Full UTC datetime for new events
        };
        response = await axios.post(`${hostConfig.API_BASE}/savetherapistschedule`, createEventData);
      }
  
      console.log('Event saved:', response.data);
      await fetchTherapistData();
    } catch (error) {
      console.error('Error saving event:', error);
      // You might want to add some user-facing error handling here
    }
  
    setIsEventModalOpen(false);
    setSelectedEvent(null);
    setEventTitle('');
    setEventStart('');
    setSelectedPatient('');
  }, [selectedEvent, eventStart, selectedTimezone, selectedPatient, fetchTherapistData]);




  const handleDeleteEvent = useCallback(async () => {
    if (selectedEvent) {
      try {
        await axios.post(`${hostConfig.API_BASE}/deletetherapistschedule`, {
           session_uuid: selectedEvent.id 
        });
        console.log('Event deleted:', selectedEvent.id);
        await fetchTherapistData();
      } catch (error) {
        console.error('Error deleting event:', error);
      }
      setIsEventModalOpen(false);
      setSelectedEvent(null);
      setEventTitle('');
      setEventStart('');
      setSelectedPatient('');
    }
  }, [selectedEvent, fetchTherapistData]);

  const getEventForCell = useCallback((date, hour, isTopHalf) => {
    const cellStart = moment(date).hour(hour).minute(isTopHalf ? 0 : 30);
    const cellEnd = cellStart.clone().add(30, 'minutes');

    return events.find(event => {
      const eventStart = moment(event.start);
      const eventEnd = eventStart.clone().add(1, 'hour');
      return eventStart.isBefore(cellEnd) && eventEnd.isAfter(cellStart);
    });
  }, [events]);

  const changeWeek = (increment) => {
    const newDate = moment(currentWeekStart).add(7 * increment, 'days').toDate();
    setCurrentWeekStart(newDate);
  };

  // JSX
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Set Schedule</DialogTitle>
      <DialogContent>
        {isLoading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">Error loading schedule: {error.message}</Typography>
        ) : (
          <div style={{ padding: '16px' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
              <IconButton onClick={() => changeWeek(-1)}><ChevronLeft /></IconButton>
              <Typography variant="h6">
                {formatDate(currentWeekStart)} - {formatDate(moment(currentWeekStart).add(6, 'days'))}
              </Typography>
              <IconButton onClick={() => changeWeek(1)}><ChevronRight /></IconButton>
            </div>
            <FormControl size="small" style={{ marginBottom: '16px', minWidth: 120 }}>
              <InputLabel>Timezone</InputLabel>
              <Select
                value={selectedTimezone}
                onChange={(e) => setSelectedTimezone(e.target.value)}
                label="Timezone"
              >
                {timezoneOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Box style={{ height: '500px', overflow: 'auto' }} ref={gridRef}>
              <div style={{ display: 'grid', gridTemplateColumns: 'auto repeat(7, 1fr)', gap: '4px', minWidth: '800px' }}>
                <div style={{ position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1 }}>Time</div>
                {days.map((day, index) => {
                  const date = moment(currentWeekStart).add(index, 'days');
                  return (
                    <div key={day} style={{ textAlign: 'center', position: 'sticky', top: 0, backgroundColor: '#fff', zIndex: 1 }}>
                      <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>{day}</Typography>
                      <Typography variant="caption">{formatDate(date)}</Typography>
                    </div>
                  );
                })}
                {hours.map(hour => (
                  <React.Fragment key={hour}>
                    <Typography variant="body2" style={{ textAlign: 'right', paddingRight: '8px', height: '48px' }}>
                      {`${hour}:00`}
                    </Typography>
                    {days.map((day, index) => {
                      const date = moment(currentWeekStart).add(index, 'days');
                      return (
                        <div key={`${day}-${hour}`} style={{ border: '1px solid #e0e0e0', cursor: 'pointer', height: '48px' }}>
                          {[true, false].map((isTopHalf) => {
                            const event = getEventForCell(date, hour, isTopHalf);
                            return (
                              <div
                                key={isTopHalf ? 'top' : 'bottom'}
                                style={{
                                  height: '24px',
                                  padding: '4px',
                                  fontSize: '12px',
                                  overflow: 'hidden',
                                  borderTop: isTopHalf ? 'none' : '1px solid #e0e0e0',
                                  backgroundColor: event ? '#1b5e20' : 'transparent',
                                  color: event ? 'white' : 'inherit'
                                }}
                                onClick={() => event ? handleEventClick(event) : handleCellClick(date.toDate(), hour, isTopHalf)}
                              >
                                {event ? event.title : ''}
                              </div>
                            );
                          })}
                        </div>
                      );
                    })}
                  </React.Fragment>
                ))}
              </div>
            </Box>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>

      <Dialog open={isEventModalOpen} onClose={() => setIsEventModalOpen(false)}>
        <DialogTitle>{selectedEvent ? 'Edit Event' : 'Create Event'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Start Time"
            type="datetime-local"
            fullWidth
            value={eventStart}
            onChange={(e) => setEventStart(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              step: 1800
            }}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Patient</InputLabel>
            <Select
              value={selectedPatient}
              onChange={(e) => setSelectedPatient(e.target.value)}
              label="Patient"
            >
              {patients.map((patient) => (
                <MenuItem key={patient.uuid} value={patient.uuid}>{patient.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="caption">
            Timezone: {timezoneOptions.find(tz => tz.value === selectedTimezone)?.label}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveEvent}>{selectedEvent ? 'Update' : 'Create'}</Button>
          {selectedEvent && (
            <Button color="error" onClick={handleDeleteEvent}>Delete</Button>
          )}
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};

const ScheduleSetterButton = () => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Tooltip title={t('therapistvideochat.controller.scheduler')} arrow>
        <IconButton 
          onClick={() => setIsCalendarOpen(true)}
          color="primary"
          aria-label="set schedule"
        >
          <CalendarMonthIcon />
        </IconButton>
      </Tooltip>
      <CalendarScheduler 
        isOpen={isCalendarOpen} 
        onClose={() => setIsCalendarOpen(false)} 
      />
    </>
  );
};

export default ScheduleSetterButton;